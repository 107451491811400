<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Kecelakaan Kerja"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tanggal - Jam Kecelakaan Kerja" label-cols-md="3">
          <date-picker
            style="width: 100%"
            type="datetime"
            v-model="$v.data.waktuKecelakaan.$model"
            :state="checkIfValid('waktuKecelakaan')"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Karyawan" label-cols-md="3">
          <multiselect
            v-model="$v.data.dataKaryawanId.$model"
            :state="checkIfValid('dataKaryawanId')"
            :options="karyawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaNIK"
            @input="itikiwir(data.dataKaryawanId)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Lokasi Kejadian" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="$v.data.lokasiKecelakaan.$model"
            :state="checkIfValid('lokasiKecelakaan')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Bagian Luka" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="$v.data.bagianLuka.$model"
            :state="checkIfValid('bagianLuka')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Kronologi Kejadian" label-cols-md="3">
          <b-form-textarea
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            v-model="$v.data.kronologiKecelakaan.$model"
            :state="checkIfValid('kronologiKecelakaan')"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Status Kronologi Kejadian" label-cols-md="3">
          <b-form-select
            v-model="$v.data.statusKronologiKecelakaan.$model"
            :options="status"
            :state="checkIfValid('statusKronologiKecelakaan')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Keterangan" label-cols-md="3">
          <b-form-textarea
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            v-model="$v.data.keterangan.$model"
            :state="checkIfValid('keterangan')"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan"],
  data() {
    return {
      data: {
        waktuKecelakaan: "",
        lokasiKecelakaan: "",
        bagianLuka: "",
        kronologiKecelakaan: "",
        statusKronologiKecelakaan: null,
        keterangan: "",
        dataKaryawanId: "",
      },
      busy: false,
      button: "Simpan",
      status: [
        { value: null, text: "Silahkan Pilih" },
        { value: "Aksiden", text: "Aksiden" },
        { value: "Insiden", text: "Insiden" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      waktuKecelakaan: {
        required,
      },
      lokasiKecelakaan: {
        required,
      },
      kronologiKecelakaan: {
        required,
      },
      bagianLuka: {
        required,
      },
      statusKronologiKecelakaan: {
        required,
      },
      keterangan: {
        required,
      },
      dataKaryawanId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      console.log(x);
    },
    simpan() {
      let vm = this;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      console.log(vm.data, "ini data");
      axios
        .post(ipBackend + "kecelakaanKerja/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENAMBAHKAN DATA KECELAKAAN KERJA",
              showing: true,
            });
            vm.data.waktuKecelakaan = "";
            vm.data.bagianLuka = "";
            vm.data.kronologiKecelakaan = "";
            vm.data.lokasiKecelakaan = "";
            vm.data.keterangan = "";
            vm.data.statusKronologiKecelakaan = null;
            vm.$bvModal.hide("modal-input");
            vm.$v.$reset();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
